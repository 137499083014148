<template>
  <div class="container-fluid">
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav">&times;</a>
      <div class="">
        <i class="fa fa-user iconUser" aria-hidden="true"></i>
        <h6 class="userNameMenu">Arquímedes de Siracusa</h6>
        <h6 class="userEmailMenu">geniodesiracusa@sicilia.it</h6>
        <a class="editProfile" href="#"><i class="fa fa-pencil mr5" aria-hidden="true"></i>Editar Perfil</a>
      </div>
      <div class="sideMenuLink">
        <a href="#" class="linkMenuSide"><i class="fa fa-gear mr5" aria-hidden="true"></i>Configuración</a>
        <router-link class="linkMenuSide" to="/predialdetail"><i class="fa fa-university mr5" aria-hidden="true"></i>Impuesto Predial</router-link>
        <a href="#" class="linkMenuSide"><i class="fa fa-industry mr5" aria-hidden="true"></i>Industria y Comercio</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-building mr5" aria-hidden="true"></i>Licencias de Construcción</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-bell mr5" aria-hidden="true"></i>Notificaciones</a>
        <hr class="hr-or mb10 hrMenuSide">
        <!-- <a href="#" class="linkMenuSide"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</a> -->
        <router-link class="linkMenuSide" to="/login"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</router-link>
      </div>
    </div>
    <!-- Header Start -->
    <div class="row headerhomeregisteredblur">
      <div class="col-12 col-md-12 mx-auto">
        <div class="openNav" v-on:click="openNav">&#9776;</div>

        <div class="headerMainTittle d-none d-sm-block">
          <i class="fa fa-university roundedIconLabelHeader mr10" aria-hidden="true"></i>
          <h5 class="labelViewHeader mt18 mb0 d-inline">Impuesto Predial</h5>
          <h5 class="subtitleDetailHeader mt8 mb0 d-inline"> Unificado y Sobretasas</h5>
        </div>

        <img class="logoHome" src="../assets/AlcaldiaLaEstrella.png" alt="AlcaldiaLaEstrella">
        <div class="iconBell"><i class="fa fa-bell colorWhite" aria-hidden="true"></i></div>
      </div>
    </div>
    <!-- /Header End -->

    <!-- Body Start -->
    <div class="row bodyhomeregistered">
      <div class="col-md-2">
        <div class="navBack">
          <router-link class="navLabelBack" to="/homeregistered">
            <i class="fa fa-angle-double-left roundedIconLabel" aria-hidden="true"></i>
            Regresar</router-link>
        </div>
      </div>
      <div class="col-md-10 avoid-clicks">
        <div class="titleDetail d-sm-none avoid-clicks">
          <h5 class="labelView mt18 mb0"><i class="fa fa-university roundedIconLabel" aria-hidden="true"></i> Impuesto Predial</h5>
          <h5 class="subtitleDetail mt8 mb0"> Unificado y Sobretasas</h5>
        </div>
        <hr class="hr-or mb10">
      </div>
    </div>

  <div class="container">

    <div class="row bodyhomeregistered mt20">
      <div class="col-md-12">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text" for="inputGroupSelect01">Tipo documento</label>
          </div>
          <select class="custom-select" id="inputGroupSelect01">
            <option selected>Selecciona...</option>
            <option value="1">Cédula de Ciudadanía</option>
            <option value="2">Cédula de Ciudadanía</option>
            <option value="3">Cédula de Ciudadanía</option>
          </select>
        </div>

        <div class="input-group mb-3">
          <input type="text" class="form-control" placeholder="Ingrese el número" aria-label="Ingrese el número" aria-describedby="basic-addon2">
          <div class="input-group-append">
            <button class="btn btn-secondary colorGreen" type="button"><i class="fa fa-search mr5" aria-hidden="true"></i>Buscar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row bodyhomeregistered mt20">
      <div class="col-md-12">
        <div class="card">
          <h6 class="card-header h5 taleft fs13">Resultados: <span class="labelResultados">2 Registros</span></h6>
          <div class="card-body card-body-paddingmod">
            <!-- <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#!" class="btn btn-primary">Go somewhere</a> -->
            <!-- ----- Accordion Start ----- -->
            <div id="accordion">
              <div class="card">
                <div class="card-header headingOneMod" id="headingOne">
                  <h5 class="mb-0">
                    <button class="btn btn-link tdnone buttonHeaderAcco" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <p class="card-text colorBlack mb0">Matricula No. <span class="labelMatricula">731152</span>
                        <span class="updownarrowcontainer">
                          <i class="fa fa-angle-up updownarrow" aria-hidden="true"></i>
                          <i class="fa fa-angle-down updownarrow d-block" aria-hidden="true"></i>
                        </span>
                      </p>
                      <p class="card-text labelResultados">52-70 Wall Street</p>
                    </button>
                  </h5>
                </div>

                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">

                    <table class="table table-sm">
                      <thead class="d-none">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Predial</td>
                          <td class="boldmedium">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td>Sobretasa Literal A</td>
                          <td class="boldmedium">$ 46.542.00</td>
                        </tr>
                        <tr>
                          <td>Pago Total</td>
                          <td class="boldmedium">$ 163.007.00</td>
                        </tr>
                      </tbody>
                    </table>


                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th colspan="2" class="boldmedium"><i class="fa fa-money colorTextGreen" aria-hidden="true"></i> Pagos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pago Trimestre 1</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td>Pago Trimestre 2</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Total Total</td>
                          <td class="boldmedium">$ 232.930.00</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td>Pago Trimestre 3</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Hasta 12/12/2020</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Total Total</td>
                          <td class="boldmedium">$ 232.930.00</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <!-- <button v-on:click="procesoPagoTotal" type="button" class="btn btn-block mybtn btn-primary btnIngresar">Ingresar</button> -->
                            <button v-on:click="procesoPagoTotal" type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Pago Total</button>
                          </td>
                          <td>
                            <!-- <button type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Pago Trimestral</button> -->
                            <button v-on:click="procesoPagoTotal" type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Pago Trimestral</button>
                          </td>
                          <td>
                            <!-- <button type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Realizar Abono</button> -->
                            <button v-on:click="procesoPagoAbono" type="button" class="btn btnGreen btn-sm btn-outline-primary"><i class="fa fa-credit-card colorWhite" aria-hidden="true"></i> Realizar Abono</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>




              <div class="card">
                <div class="card-header headingOneMod" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link tdnone buttonHeaderAcco collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <p class="card-text colorBlack mb0">Matricula No. <span class="labelMatricula">6484152</span>
                        <span class="updownarrowcontainer">
                          <i class="fa fa-angle-up updownarrow" aria-hidden="true"></i>
                          <i class="fa fa-angle-down updownarrow d-block" aria-hidden="true"></i>
                        </span>
                      </p>
                      <p class="card-text labelResultados">52-85 Wall Street</p>
                    </button>
                  </h5>
                </div>

                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">

                    <table class="table table-sm">
                      <thead class="d-none">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Predial</td>
                          <td class="boldmedium">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td>Sobretasa Literal A</td>
                          <td class="boldmedium">$ 46.542.00</td>
                        </tr>
                        <tr>
                          <td>Pago Total</td>
                          <td class="boldmedium">$ 163.007.00</td>
                        </tr>
                      </tbody>
                    </table>


                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th colspan="2" class="boldmedium"><i class="fa fa-money colorTextGreen" aria-hidden="true"></i> Pagos</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Pago Trimestre 1</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td>Pago Trimestre 2</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Total Total</td>
                          <td class="boldmedium">$ 232.930.00</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td>Pago Trimestre 3</td>
                          <td class="boldmedium colorTextGreen">$ 116.465.00</td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Hasta 12/12/2020</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td class="boldmedium">Total Total</td>
                          <td class="boldmedium">$ 232.930.00</td>
                        </tr>
                      </tbody>
                    </table>

                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td><button type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Pago Total</button></td>
                          <td><button type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Pago Trimestral</button></td>
                          <td><button type="button" class="btn btn-sm btn-outline-primary"><i class="fa fa-credit-card colorBlue" aria-hidden="true"></i> Realizar Abono</button></td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>


              <!-- <div class="card">
                <div class="card-header" id="headingTwo">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Collapsible Group Item #2
                    </button>
                  </h5>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <h5 class="mb-0">
                    <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      Collapsible Group Item #3
                    </button>
                  </h5>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div> -->

            </div>
<!-- ----- Accordion End ----- -->
          </div>
        </div>
      </div>
    </div>

  </div>
    <!-- /Body End -->
  </div>
</template>
<script>

// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  mounted() {
    // $('body').css('background-color', 'orange');
		$('body').addClass("bgHomeRegistered");
  },
  beforeMount() {

  },
  methods: {

    openNav() {
      // document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("mySidenav").style.width = "300px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },
    procesoPagoTotal(){
      this.$router.replace("zonapagos");
    },
    procesoPagoAbono(){
      this.$router.replace("zonapagosabono");
    },


  }
};

$("#signup").click(function() {
$("#first").fadeOut("fast", function() {
$("#second").fadeIn("fast");
});
});
</script>
<style>
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 160;
  top: 0;
  left: 0;
  /* background-color: #111; */
  background-color: rgba(0,0,0,0.9);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>
