<template>
  <div class="container-fluid">
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav">&times;</a>
      <div class="">
        <i class="fa fa-user iconUser" aria-hidden="true"></i>
        <h6 class="userNameMenu">Arquímedes de Siracusa</h6>
        <h6 class="userEmailMenu">geniodesiracusa@sicilia.it</h6>
        <a class="editProfile" href="#"><i class="fa fa-pencil mr5" aria-hidden="true"></i>Editar Perfil</a>
      </div>
      <div class="sideMenuLink">
        <a href="#" class="linkMenuSide"><i class="fa fa-gear mr5" aria-hidden="true"></i>Configuración</a>
        <router-link class="linkMenuSide" to="/predialdetail"><i class="fa fa-university mr5" aria-hidden="true"></i>Impuesto Predial</router-link>
        <!-- <a href="#" class="linkMenuSide"><i class="fa fa-university mr5" aria-hidden="true"></i>Impuesto Predial</a> -->
        <a href="#" class="linkMenuSide"><i class="fa fa-industry mr5" aria-hidden="true"></i>Industria y Comercio</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-building mr5" aria-hidden="true"></i>Licencias de Construcción</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-bell mr5" aria-hidden="true"></i>Notificaciones</a>
        <hr class="hr-or mb10 hrMenuSide">
        <!-- <a href="#" class="linkMenuSide"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</a> -->
        <!-- <div v-on:click="login"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</div> -->
        <router-link class="linkMenuSide" to="/"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</router-link>
      </div>
    </div>
    <!-- Header Start -->
    <div class="row headerhomeregisteredblur">
      <div class="col-12 col-md-12 mx-auto">
        <div class="openNav" v-on:click="openNav">&#9776;</div>
        <img class="logoHome" src="../assets/AlcaldiaLaEstrella.png" alt="AlcaldiaLaEstrella">
        <div class="iconBell"><i class="fa fa-bell colorWhite" aria-hidden="true"></i></div>
      </div>
    </div>
    <!-- /Header End -->

    <!-- Body Start -->
    <div class="row bodyhomeregistered">
      <div class="col-md-12">
        <h5 class="labelWelcome mt18 mb0">Bienvenido,</h5>
        <h6 class="colorBlue">Arquímedes de Siracusa</h6>
        <hr class="hr-or mb10">
      </div>
    </div>

  <div class="container">
    <div class="row bodyhomeregistered mt10">

      <div class="col-md-4 pl5pr5 itemBox">
        <div class="tarespons">
          <i class="fa fa-university roundedIcon" aria-hidden="true"></i>
          <router-link class="homeLabelIcon" to="/predialdetail">
            <h5 class="boldmedium mt8 mb0">Impuesto Predial</h5>
            <h6 class="colorGrey">Consulta y Transacciones</h6>
          </router-link>
          <!-- <div class="homeLabelIcon">
            <h5 class="boldmedium mt8 mb0">Impuesto Predial</h5>
            <h6>Consulta y Transacciones</h6>
          </div> -->
        </div>
      </div>

      <div class="col-md-4 pl5pr5 itemBox">
        <div class="tarespons">
          <i class="fa fa-industry roundedIcon" aria-hidden="true"></i>
          <router-link class="homeLabelIcon" to="/predialdetail">
            <h5 class="boldmedium mt8 mb0">Industria y Comercio</h5>
            <h6 class="colorGrey">Consulta y Transacciones</h6>
          </router-link>
          <!-- <div class="homeLabelIcon">
            <h5 class="boldmedium mt8 mb0">Industria y Comercio</h5>
            <h6>Consulta y Transacciones</h6>
          </div> -->
        </div>
      </div>

      <div class="col-md-4 pl5pr5 itemBox">
        <div class="tarespons">
          <i class="fa fa-building roundedIcon" aria-hidden="true"></i>
          <router-link class="homeLabelIcon" to="/predialdetail">
            <h5 class="boldmedium mt8 mb0">Licencias de Construcción</h5>
            <h6 class="colorGrey">Consulta y Transacciones</h6>
          </router-link>
          <!-- <div class="homeLabelIcon">
            <h5 class="boldmedium mt8 mb0">Licencias de Construcción</h5>
            <h6>Consulta y Transacciones</h6>
          </div> -->
        </div>
      </div>

    </div>
  </div>
    <!-- /Body End -->
  </div>
</template>
<script>

// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  mounted() {
    // $('body').css('background-color', 'orange');
		$('body').addClass("bgHomeRegistered");
  },
  beforeMount() {

  },
  methods: {

    openNav() {
      // document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("mySidenav").style.width = "300px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },


  }
};

$("#signup").click(function() {
$("#first").fadeOut("fast", function() {
$("#second").fadeIn("fast");
});
});
</script>
<style>
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /* background-color: #111; */
  background-color: rgba(0,0,0,0.9);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>
