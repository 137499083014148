<template>
  <div class="container-fluid">
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav">&times;</a>
      <div class="">
        <i class="fa fa-user iconUser" aria-hidden="true"></i>
        <h6 class="userNameMenu">Arquímedes de Siracusa</h6>
        <h6 class="userEmailMenu">geniodesiracusa@sicilia.it</h6>
        <a class="editProfile" href="#"><i class="fa fa-pencil mr5" aria-hidden="true"></i>Editar Perfil</a>
      </div>
      <div class="sideMenuLink">
        <a href="#" class="linkMenuSide"><i class="fa fa-gear mr5" aria-hidden="true"></i>Configuración</a>
        <router-link class="linkMenuSide" to="/predialdetail"><i class="fa fa-university mr5" aria-hidden="true"></i>Impuesto Predial</router-link>
        <a href="#" class="linkMenuSide"><i class="fa fa-industry mr5" aria-hidden="true"></i>Industria y Comercio</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-building mr5" aria-hidden="true"></i>Licencias de Construcción</a>
        <a href="#" class="linkMenuSide"><i class="fa fa-bell mr5" aria-hidden="true"></i>Notificaciones</a>
        <hr class="hr-or mb10 hrMenuSide">
        <!-- <a href="#" class="linkMenuSide"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</a> -->
        <router-link class="linkMenuSide" to="/login"><i class="fa fa-sign-out mr5" aria-hidden="true"></i>Cerrar Sesión</router-link>
      </div>
    </div>
    <!-- Header Start -->
    <div class="row headerhomeregisteredblur">
      <div class="col-12 col-md-12 mx-auto">
        <div class="openNav" v-on:click="openNav">&#9776;</div>

        <div class="headerMainTittle d-none d-sm-block">
          <i class="fa fa-university roundedIconLabelHeader mr10" aria-hidden="true"></i>
          <h5 class="labelViewHeader mt18 mb0 d-inline">Zona</h5>
          <h5 class="subtitleDetailHeader mt8 mb0 d-inline"> de Pagos</h5>
        </div>

        <img class="logoHome" src="../assets/AlcaldiaLaEstrella.png" alt="AlcaldiaLaEstrella">
        <div class="iconBell"><i class="fa fa-bell colorWhite" aria-hidden="true"></i></div>
      </div>
    </div>
    <!-- /Header End -->

    <!-- Body Start -->
    <div class="row bodyhomeregistered">
      <div class="col-md-2">
        <div class="navBack">
          <router-link class="navLabelBack" to="/PredialDetail">
            <i class="fa fa-angle-double-left roundedIconLabel" aria-hidden="true"></i>
            Regresar</router-link>
        </div>
      </div>
      <div class="col-md-10 avoid-clicks">
        <div class="titleDetail d-sm-none avoid-clicks">
          <h5 class="labelView mt18 mb0"><i class="fa fa-university roundedIconLabel" aria-hidden="true"></i> Zona</h5>
          <h5 class="subtitleDetail mt8 mb0"> de Pagos</h5>
        </div>
        <hr class="hr-or mb10">
      </div>
    </div>

  <div class="container">

    <div class="row bodyhomeregistered mt20">
      <div class="col-md-12">
        <!-- <h3 class="taleft">Zona de pagos</h3> -->
        <!-- <h4>Confirmación</h4> -->
        <!-- <p>Usted ha seleccionado pagar el siguiente item:</p> -->
      </div>
    </div>

    <div class="row bodyhomeregistered mt20">
      <div class="col-md-12">

        <div class="card bg-light mb-3 mb20">
          <div class="card-header">
            <h5>Confirmación zona de pagos</h5>
            <h6>Usted ha seleccionado pagar el siguiente item:</h6>
          </div>
          <div class="card-body">
            <h5 class="card-title bold taleft">Referencia No.</h5>
            <p class="card-text fs19 taleft">874845965</p>
            <h5 class="card-title taleft">Arquímedes de Siracusa</h5>

            <h5 class="card-text bold taleft">Impuesto predial</h5>
            <h5 class="card-title bold taleft">Matricula No. <span>731152</span></h5>
            <p class="card-text taleft">52-70 Wall Street</p>
            <div class="taleft">
              <h5 class="card-title bold taleft d-inline">Pago total: </h5>
              <h5 class="colorTextGreen bold taleft d-inline">$ 232.930.00</h5>
            </div>
          </div>
          <a class="navLabelBack mb5" href="#">
            Revisar terminos y condiciones
          </a>
          <button type="button" class="btn btn-block mybtn btn-primary btnIngresar">Finalizar Pago</button>
          <div class="mb20">
          </div>
        </div>

      </div>
    </div>

  </div>
    <!-- /Body End -->
  </div>
</template>
<script>

// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  mounted() {
    // $('body').css('background-color', 'orange');
		$('body').addClass("bgHomeRegistered");
  },
  beforeMount() {

  },
  methods: {

    openNav() {
      // document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("mySidenav").style.width = "300px";
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0";
    },


  }
};

$("#signup").click(function() {
$("#first").fadeOut("fast", function() {
$("#second").fadeIn("fast");
});
});
</script>
<style>
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 160;
  top: 0;
  left: 0;
  /* background-color: #111; */
  background-color: rgba(0,0,0,0.9);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
</style>
