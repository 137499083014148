<template>
  <!-- Login form -->
  <div class="container">
    <div class="row">
      <div class="col-md-5 mx-auto">
        <div id="first" class="mt50">
          <div class="myform form">
            <div class="logo mb0">
              <div class="col-md-12 text-center">
                <img class="logoLogin" src="../assets/AlcaldiaLaEstrella.png" alt="">
                <hr class="hr-or mb10">
                <p class="text-center labelBoldLogin">Recuperar Contrasena</p>
              </div>
            </div>
            <form action="" method="post" name="login">
               <div class="form-group">
                  <label class="taleft">Ingrese su correo electrónico y sigua las instrucciones que se le enviarán.</label>
                  <input type="email" name="email"  class="form-control roundedcorners" id="email" aria-describedby="emailHelp" placeholder="Correo Electrónico">
               </div>
               <div class="col-md-12 text-center mb12">
                 <!-- <button v-on:click="login" type="button" class="btn btn-block mybtn btn-primary btnIngresar">Ingresar</button> -->
                 <!-- <button type="submit" class="btn mybtn btnGrey btn-primary btnIngresar d-inline">Atrás</button> -->
                  <router-link class="btn mybtn btnGrey btn-primary btnIngresar d-inline" to="/">Atrás</router-link>
                  <button v-on:click="login" type="button" class="btn btn-block mybtn btn-primary btnIngresar d-inline">Recuperar</button>
               </div>
             </form>
					</div>
				</div>
			</div>
		</div>
	</div>
  <!-- /Login form -->
</template>
<script>

// Importamos JQuery
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default {
  data() {
    return {
      email: "",
      password: ""
    };
  },
  mounted() {
    if( $("body").hasClass( "bgHomeRegistered" ) ){
      $("body").removeClass( "bgHomeRegistered" );
      $('body').addClass("bgLogin");
    } else {
      $('body').addClass("bgLogin");
    }
  },
  beforeMount() {

  },
  methods: {

    logout() {
      this.$router.replace("/");
    },


  }
};

$("#signup").click(function() {
$("#first").fadeOut("fast", function() {
$("#second").fadeIn("fast");
});
});
</script>
<style>
</style>
